import adminRoutes from './admin'
import userRoutes from './user'
import websiteRoutes from './website'

const routes = [
  ...adminRoutes,
  ...userRoutes,
  ...websiteRoutes
  // {
  //   path: "*",
  //   redirect: "/auth/login",
  // },
];

export default routes;
