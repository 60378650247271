<template>
  <v-app class="app" >
    <router-view ></router-view>
    <snackbar></snackbar>
    <loader v-if="loading"></loader>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Snackbar from "@/components/global/Snackbar";
import Loader from "@/components/global/Loader";

export default {
  name: "App",
  components: {
    Snackbar,
    Loader,
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  data:() => ({
  }),
  watch:{
    $route(){
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 10);
    }
  },
  methods: {},
  async mounted() {
  },
};
</script>

<style lang="scss">
.app {
  .v-application--wrap {
  }

}
</style>
