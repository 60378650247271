import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "vue-transitions-css";

Vue.use(Vuetify);
const opts = {
  rtl: localStorage.getItem("language") == "ar",
  theme: {
    themes: {
      light: {
        primary: "#DA8F45",
        primaryWebsite: '#E88A2E',
        primaryLightbg: '#f8e9da',
        primarytext: "#7C8DB5",
        secondary: '#c38947',
        "lightprimary-1": "#C1EBFF",
        lightblue: "#00ABFF",
        red: "#D9534F",
        lightprimary: "#C9DAFF",
        graybg: "#F0F5F8",
        lightbg: "#F9FAFF",
        greyText: "#787878",
        darkgreyText: "#393939",
        lightbluebg: "#EBF1FF",
        lightblackText: "#4E4E4E",
        darkgray: "#DCDCDC",
        black: "#222222",
        grey: "#4D4D4D",
        greyBtn: '#9E9E9E',
        darkblack: "#000000",
        darkblue: "#111E4E",
        green: "#007A3D",
        halfwhite: "#ffffff96",
        lightred: "#FFDEDE",
        orange: "#FF5400",
        lightorange: "#FFAA80",
        yeloow: "FFCD11",
        greybg: '#fcfcfc',
        lightTextGrey: '#6C6C6C'
      },
    },
  },
};

export default new Vuetify(opts);
