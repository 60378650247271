<template>
    <div
      class="base-input base-select w-100 dashboard-input"
      :style="{ width: width }"
    >
      <span class="lightblackText--text font-16 input-text"> {{ title }}</span>
      <v-combobox
        :items="items"
        outlined
        ref="select"
        :placeholder="placeholder"
        dense
        :hide-details="hideDetails"
        :item-value="itemValue"
        :item-text="itemText"
        :value="value"
        :rules="rules"
        :multiple="multiple"
        :height="height"
        :return-object="returnObject"
        :clearable="clearable"
        @input="$emit('input', $event)"
        @keyup="$emit('keyup', $event)"
        :chips="selectAll"
        :search-input="searchInput"
      >
        <template v-if="slotOptions">
          <div>item</div>
        </template>
      </v-combobox>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      items: {
        type: Array,
        default: () => [],
      },
      placeholder: {
        type: String,
        default: "",
      },
      itemValue: {
        type: String,
        default: "value",
      },
      itemText: {
        type: String,
        default: "title",
      },
      width: {
        type: String,
        default: "200px",
      },
      height: {
        type: String,
        default: "50px",
      },
      title: {
        type: String,
        default: "",
      },
      value: {
        validator: () => true,
      },
      rules: {
        type: Array,
        default: () => [(v) => !!v || "this field is require"],
      },
      hideDetails: {
        type: Boolean,
        default: true,
      },
      selectAll: {
        type: Boolean,
        default: false,
      },
      returnObject: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      slotOptions: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      value: {
        handler() {
          this.searchInput = null;
        },
      },
    },
    data: () => ({
      searchInput: "",
    }),
    mounted() {
      this.$refs.select.lastItem = 500;
    },
  };
  </script>
  
  <style lang="scss">
  .base-select {
    fieldset {
      border: 0px;
      background: #e5ebff6c;
    }
    .error--text {
      .v-input__slot {
        fieldset {
          border: 1px solid #e44358 !important;
        }
      }
    }
  
    .v-input__append-inner {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0px !important;
    }
  }
  .v-input__icon--clear {
    position: relative;
    right: 20px !important;
  }
  </style>
  