
const routes = [
    {
        path: "/user",
        component: () => import("../layout/user/Sidebar.vue"),
        meta: {
            requiresAuth: true,
            title: "User-",
        },
        children: [
            {
                path: "/",
                name: "User-overview",
                meta: {
                    requiresAuth: true,
                    title: "User-overview",
                },
                component: () => import("../views/user-dashboard/pages/overview/Index.vue"),
            },
            {
                path: "/user/info",
                name: "User-info",
                meta: {
                    requiresAuth: true,
                    title: "User-info",
                },
                component: () => import("../views/user-dashboard/pages/user-info/Index.vue"),
            },
            {
                path: "/user/requests",
                name: "User-requests",
                meta: {
                    requiresAuth: true,
                    title: "User-requests",
                },
                component: () => import("../views/user-dashboard/pages/requests/Index.vue"),
            },
            {
                path: "/user/requests/add",
                name: "User-add-requests",
                meta: {
                    requiresAuth: true,
                    title: "User-requests",
                },
                component: () => import("../views/user-dashboard/pages/requests/add/Index.vue"),
            },
            {
                path: "/user/requests/:id",
                name: "User-edit-requests",
                meta: {
                    requiresAuth: true,
                    title: "User-requests",
                },
                component: () => import("../views/user-dashboard/pages/requests/add/Index.vue"),
            },
            {
                path: "/user/products",
                name: "User-products",
                meta: {
                    requiresAuth: true,
                    title: "User-products",
                },
                component: () => import("../views/user-dashboard/pages/products/Index.vue"),
            },
            {
                path: "/user/notifications",
                name: "User-notifications",
                meta: {
                    requiresAuth: true,
                    title: "User-notifications",
                },
                component: () => import("../views/user-dashboard/pages/user-notifications/Index.vue"),
            },
            {
                path: "/user/company-info",
                name: "User-company-info",
                meta: {
                    requiresAuth: true,
                    title: "User-company-info",
                },
                component: () => import("../views/user-dashboard/pages/company-info/Index.vue"),
            },
            {
                path: "/user/company-notifications",
                name: "User-company-notifications",
                meta: {
                    requiresAuth: true,
                    title: "User-company-notifications",
                },
                component: () => import("../views/user-dashboard/pages/company-notifications/Index.vue"),
            },
            {
                path: "/user/messages",
                name: "User-messages",
                meta: {
                    requiresAuth: true,
                    title: "User-messages",
                },
                component: () => import("../views/user-dashboard/pages/messages/Index.vue"),
            },
            {
                path: "/user/company/add",
                name: "User-company-add",
                meta: {
                    requiresAuth: true,
                    title: "User-company-add",
                },
                component: () => import("../views/user-dashboard/pages/add-company/Index.vue"),
            },

            {
                path: "/user/elements-for-verify",
                name: "User-elements-for-verify",
                meta: {
                    requiresAuth: true,
                    title: "User-elements-for-verify",
                },
                component: () => import("../views/user-dashboard/pages/elements-for-verify/Index.vue"),
            },  
            
            {
                path: "/user/company-messages",
                name: "User-company-messages",
                meta: {
                    requiresAuth: true,
                    title: "User-company-messages",
                },
                component: () => import("../views/user-dashboard/pages/company-messages/Index.vue"),
            },          
        ],
    }];

export default routes